.footer {
    background-color: #fff;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    text-align: center;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .icon-link {
    color: #6c757d;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .icon-link:hover {
    color: #343a40;
  }
  