/* Main body styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.work-item {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  text-align: center;
  height: 400px; /* Set a fixed height for uniform size */
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-item img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fits without being stretched */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 20px; /* Added padding for better text positioning */
}

.overlay-text {
  text-align: left;
  padding: 20px;
}

.work-item:hover .overlay {
  opacity: 1;
}

.overlay ul {
  list-style: none;
  padding: 0;
}

.overlay ul li {
  margin-bottom: 10px;
}
