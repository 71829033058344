/* Custom styles for the header */
.header {
  padding-top: 20px; /* Adjust this value to move the content down */
}

.header-name h1 {
  color: #343a40; /* Dark color for the name */
  font-size: 1.5rem; /* Adjust the font size */
  font-weight: bold; /* Bold font weight */
  margin-left: 2rem; /* Adjust left margin */
}

.header-nav .nav-link {
  color: #6c757d; /* Default text color */
  font-size: 1rem; /* Adjust font size */
  font-weight: normal; /* Normal font weight */
  margin: 0 20px; /* Space between links */
  text-decoration: none; /* Remove underline */
  transition: color 0.2s, font-weight 0.2s; /* Smooth transition */
}

.header-nav .nav-link:hover,
.header-nav .nav-link:focus {
  color: #343a40; /* Darker text color on hover/focus */
  font-weight: bold; /* Bold font weight on hover/focus */
}
